/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SettingModal({
  show,
  onHide,
  imageURLs,
  setDefaultZoomRate,
  defaultZoomRate,
  setIsAutoSave,
  isAutoSave,
  disableShowAutoSave,
  nomaskedImages,
  setNomaskedImages,
}) {
  const { t, i18n } = useTranslation();
  const [zoomInRateLabels, setZoomInRateLabels] = useState([
    '極小',
    '小',
    '中',
    '大',
  ]);
  useEffect(() => {
    if (i18n.language === 'en') {
      setZoomInRateLabels(['very small', 'small', 'medium', 'large']);
    } else {
      setZoomInRateLabels(['極小', '小', '中', '大']);
    }
  }, [i18n]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="setting_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('表示設定')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="f-ja f-modal_title_sub">{t('表示する画像の設定')}</h4>
        <Row className="setting_modal-content_wrap">
          <Row className="f-ja">
            <Col sm={8}>{t('画像')}</Col>
            <Col style={{ textAlign: 'center' }}>{t('画像表示')}</Col>
            <Col style={{ textAlign: 'center' }}>{t('マスクなし')}</Col>
          </Row>
          <hr />
          {imageURLs.map((img, index) => (
            <Row className="content_wrap-table">
              <Col sm={8} className="f-en">
                {img.name}
              </Col>
              <Col className="text-center">
                <Form.Check
                  type="checkbox"
                  defaultChecked={img.visible}
                  onChange={(e) => {
                    img.visible = e.target.checked;
                  }}
                />
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  checked={nomaskedImages[index]}
                  onChange={() => {
                    setNomaskedImages((prevNomaskedImages) => {
                      const prevNomaskedImagesCopy =
                        prevNomaskedImages.concat();
                      prevNomaskedImagesCopy[index] =
                        !prevNomaskedImagesCopy[index];

                      return prevNomaskedImagesCopy;
                    });
                  }}
                />
              </Col>
            </Row>
          ))}
        </Row>
        {defaultZoomRate !== 0 && (
          <>
            <h4 className="f-ja f-modal_title_sub">
              {t('手動測定時の画面拡大率の選択')}
            </h4>
            <Form className="setting_modal-content_wrap f-ja">
              <Row>
                <Col>
                  <Form.Check
                    name="group1"
                    label={zoomInRateLabels[0]}
                    type="radio"
                    onChange={() => setDefaultZoomRate(250)}
                    defaultChecked={defaultZoomRate === 250 && 'true'}
                  />
                </Col>
                <Col>
                  <Form.Check
                    name="group1"
                    label={zoomInRateLabels[1]}
                    type="radio"
                    onChange={() => setDefaultZoomRate(50)}
                    defaultChecked={defaultZoomRate === 50 && 'true'}
                  />
                </Col>
                <Col>
                  <Form.Check
                    name="group1"
                    label={zoomInRateLabels[2]}
                    type="radio"
                    onChange={() => setDefaultZoomRate(40)}
                    defaultChecked={defaultZoomRate === 40 && 'true'}
                  />
                </Col>
                <Col>
                  <Form.Check
                    name="group1"
                    label={zoomInRateLabels[3]}
                    type="radio"
                    onChange={() => setDefaultZoomRate(30)}
                    defaultChecked={defaultZoomRate === 30 && 'true'}
                  />
                </Col>
              </Row>
            </Form>
          </>
        )}
        {!disableShowAutoSave && (
          <>
            <h4 className="f-ja f-modal_title_sub">{t('オートセーブ')}</h4>
            <Row className="setting_modal-content_wrap">
              <Col>
                <Form.Check
                  name="group2"
                  label="ON"
                  type="radio"
                  onChange={() => setIsAutoSave(true)}
                  defaultChecked={isAutoSave && 'true'}
                  className="f-en"
                  style={{ fontWeight: 600 }}
                />
              </Col>
              <Col>
                <Form.Check
                  name="group2"
                  label="OFF"
                  type="radio"
                  onChange={() => setIsAutoSave(false)}
                  defaultChecked={!isAutoSave && 'true'}
                  className="f-en"
                  style={{ fontWeight: 600 }}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={onHide} className="btn-style box_border_blue f-ja">
          {t('閉じる')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SettingModal;

SettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  imageURLs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDefaultZoomRate: PropTypes.func.isRequired,
  defaultZoomRate: PropTypes.number.isRequired,
  setIsAutoSave: PropTypes.func.isRequired,
  isAutoSave: PropTypes.bool.isRequired,
  disableShowAutoSave: PropTypes.bool.isRequired,
  nomaskedImages: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setNomaskedImages: PropTypes.func.isRequired,
};
