// バックエンドから返されるerror.response.dataのエラー文をパースし
// エラー発生箇所とエラー理由を取得する。
// たまにパース不可能なエラーが返るので、その場合はデフォルトの文を返す。
exports.errorStringProcess = (errorString) => {
  const defaultErrorObject = {
    place: '現在の解析',
    reason:
      '正体不明のエラーが発生しました。数回やり直してもエラーが出る場合、開発者にlog.txtをメールで送信して下さい。「ログをダウンロード」ボタンからlog.txtをダウンロードできます。',
  };

  let errorObject;
  try {
    const errorStringSplitted = errorString.split(',');
    if (errorStringSplitted.length !== 2) {
      errorObject = defaultErrorObject;
    } else {
      errorObject = {
        place: errorStringSplitted[0],
        reason: errorStringSplitted[1],
      };
    }
  } catch {
    errorObject = defaultErrorObject;
  }

  return errorObject;
};

// レポート文のMEA欄に入力された文字列を解析し、有効な名前の入力方法になっているか調べる。
// MEA欄の名前は複数入力可能で、カンマで分割できる。
// カンマで分割されたそれぞれが以下の例のいずれかにマッチしていないといけない。
// K. Sugiura or A. B. Sugiura or A.-B. Sugiura
exports.checkValidMEANames = (MEANameString) => {
  const patterns = [
    /^[A-Z]\. [A-Z][a-z]+$/,
    /^[A-Z]\. [A-Z]. [A-Z][a-z]+$/,
    /^[A-Z]\.-[A-Z]\. [A-Z][a-z]+/,
  ];
  const nameSplittedList = MEANameString.split(',');
  let wrongNamePattern = false;
  nameSplittedList.forEach((name) => {
    let thisNameMatchesPattern = false;
    patterns.forEach((pattern) => {
      if (pattern.test(name.trim())) thisNameMatchesPattern = true;
    });
    if (!thisNameMatchesPattern) wrongNamePattern = true;
  });

  return wrongNamePattern;
};
