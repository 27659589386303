import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AiFillFile, AiFillSetting } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import { BsTranslate } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import CONSTANT from '../../utils/CONSTANTS';

function StellarGlobeHelpModal({ show, onHide }) {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('ヘルプ')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered>
          <tbody className="help-modal-table">
            <tr>
              <th className="f-ja">{t('移動')}</th>
              <td className="f-ja">
                {t('画面をドラッグすると視線の方向を移動させることができます')}
              </td>
            </tr>
            <tr>
              <th className="f-ja">
                {t('拡大')}
                <br />
                {t('縮小')}
              </th>
              <td className="f-ja">
                {t(
                  'スクロール操作(マウスのホイールを回転させる、トラックバッドの上を2本指でスライドする)などで視野の広さを拡大縮小できます',
                )}
              </td>
            </tr>
            <tr>
              <th className="f-ja">{t('戻る')}</th>
              <td className="f-ja">
                {t(
                  '拡大しすぎて迷子になった時など、左上の「領域」ボタンもしくは「全天に戻る」ボタンを押すことで適切な視野に戻ることができます',
                )}
              </td>
            </tr>
            <tr>
              <th className="f-ja">{t('手動選択')}</th>
              <td className="f-ja">
                {t(
                  '夜空に表示されている枠を選ぶことでその中にある画像を選択できます。',
                )}
                {t('大領域')}
                <HiOutlineArrowSmRight />
                {t('小領域')}
                <HiOutlineArrowSmRight />
                {t('観測日')}
                <HiOutlineArrowSmRight />
                {t('画像')}
                {t('の順で絞り込んでいきます')}
              </td>
            </tr>
            <tr>
              <th className="f-ja">{t('自動選択')}</th>
              <td className="f-ja">
                {t(
                  '「自動選択」ボタンを押すことで解析しやすいおすすめの画像を自動で選ぶことができます',
                )}
              </td>
            </tr>
            <tr>
              <th>
                <AiFillFile size={CONSTANT.iconSize28px} />
              </th>
              <td className="f-ja">
                {t(
                  '画像の選択状況を確認できます。画像選択中はアイコンの横に選択画像枚数が表示されます',
                )}
              </td>
            </tr>
            <tr>
              <th>
                <MdDeleteForever size={CONSTANT.iconSize28px} />
              </th>
              <td className="f-ja">{t('画像の選択をキャンセルできます')}</td>
            </tr>
            <tr>
              <th>
                <BsTranslate size={CONSTANT.iconSize28px} />
              </th>
              <td className="f-ja">
                アプリ全体で日本語・英語の切り替えをします / Switch language
                between English and Japanese
              </td>
            </tr>
            <tr>
              <th>
                <AiFillSetting size={CONSTANT.iconSize28px} />
              </th>
              <td className="f-ja">
                {t(
                  '設定画面を開きます。複数日にまたがり画像を選ぶことを可能にすること、画像選択モード・鑑賞モードの切り替え、ができます',
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={onHide} className="btn-style box_border_blue">
          {t('閉じる')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StellarGlobeHelpModal;

StellarGlobeHelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
