import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { UserIDContext } from '../functional/context';

function AgreeModal({ show, setShow }) {
  const reactApiUri = process.env.REACT_APP_API_URI;
  const { userId } = useContext(UserIDContext);
  const [agreedArray, setAgreedArray] = useState([false, false, false]);
  const [allAgreed, setAllAgreed] = useState(false);
  const agreeSentenceArray = [
    <>
      私は、測定の結果が自身の名前と共にMinor Planet
      Centerに送信されることを踏まえ、責任を持った測定を行います。
      <br />I understood that my measurements are sent to the Minor Planet
      Center with my name, and I am responsible for my measurements.
    </>,
    <>
      私は、
      <a
        href="https://web-coias.u-aizu.ac.jp/guide"
        target="_blank"
        rel="noreferrer noopener"
      >
        使い方ガイド動画
      </a>
      の少なくとも基本編を全て視聴し、移動天体とノイズの見分け方や正しい測定方法を把握しました。
      <br /> I watched at least basic part of{' '}
      <a
        href="https://web-coias.u-aizu.ac.jp/guide"
        target="_blank"
        rel="noreferrer noopener"
      >
        how to use movie
      </a>{' '}
      and understood proper ways for measurements.
    </>,
    <>
      私は、
      <a
        href="https://web-coias.u-aizu.ac.jp/regulation"
        target="_blank"
        rel="noreferrer noopener"
      >
        利用規程
      </a>
      に同意します。 <br /> I agreed with{' '}
      <a
        href="https://web-coias.u-aizu.ac.jp/regulation"
        target="_blank"
        rel="noreferrer noopener"
      >
        Terms of Service
      </a>
      .
    </>,
  ];

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          重要確認事項 / important confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            COIASは皆様の目で移動天体を見つけていただくアプリケーションです。探索モードでは、自動検出された移動天体候補を表す黒い四角が表示されますが、ほとんどはノイズであり、本物の移動天体の選別は皆様に行なっていただきます。皆様の測定結果は、ご自身の名前と共に国際的な機関Minor
            Planet
            Centerに報告されるため、責任を持った測定をお願いします。上記を踏まえ、以下について確認・同意をお願いします。
          </span>
          <br />
          COIAS is an application to find moving object candidates with your
          eyes. In search mode, most black rectangles are noises and you have to
          distinguish true moving objects. Your measurements are sent to the IAU
          (International Astronomical Union) Minor Planet Center with your name,
          so please be responsible for your measurements. Please agree with the
          following commitments.
        </div>
        <Table style={{ color: 'black' }} className="agree-modal-table" striped>
          <tbody>
            {agreedArray.map((eachAgreed, index) => (
              <tr>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={eachAgreed}
                    onChange={() => {
                      const copyAgreedArray = [...agreedArray];
                      copyAgreedArray[index] = !agreedArray[index];
                      setAgreedArray(copyAgreedArray);

                      if (copyAgreedArray.includes(false)) {
                        setAllAgreed(false);
                      } else {
                        setAllAgreed(true);
                      }
                    }}
                  />
                </td>
                <td>{agreeSentenceArray[index]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div>
          (この確認事項は定期的に表示されます / This confirmation is shown
          regularly)
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="primary"
          className="btn-style box_blue"
          disabled={!allAgreed}
          onClick={() => {
            axios.put(`${reactApiUri}agreed?user_id=${userId}`).catch((e) => {
              console.log(e);
            });
            setShow(false);
          }}
        >
          利用開始 / Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AgreeModal;

AgreeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
