import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ThankYouModal({
  thankYouModalShow,
  onClickOk,
  thankYouMessageBig,
  thankYouMessageSmall,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={thankYouModalShow}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: '#5c636a', fontWeight: 'bold' }}
        >
          {t('測定完了です')}!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: '#5c636a', whiteSpace: 'pre-line' }}>
        <div className="celebrate-image">
          <img
            alt=""
            src="/app/applause.jpg"
            width="100%"
            height="100%"
            className="d-inline-block align-top"
          />
          <div className="celebrate-image-text">
            <h3>
              <b>{thankYouMessageBig}</b>
            </h3>
            <h3>{thankYouMessageSmall}</h3>
            <h4>{t('次の測定へ進みましょう!\n')}</h4>
            <aside>
              {t(
                '報告いただいた情報は小惑星センター(MPC)にて保管され、追観測に成功したものが新天体と認められます。詳しく知りたい方は、',
              )}
              <a
                href="https://web-coias.u-aizu.ac.jp/details/procedure"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('「小惑星の発見と名前がつくまで」')}
              </a>
              {t('をご覧ください。')}
            </aside>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          variant="success"
          className="btn-style box_blue"
          onClick={() => onClickOk()}
        >
          {t('画像選択に戻る')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ThankYouModal;

ThankYouModal.propTypes = {
  thankYouModalShow: PropTypes.bool.isRequired,
  onClickOk: PropTypes.func.isRequired,
  thankYouMessageBig: PropTypes.string.isRequired,
  thankYouMessageSmall: PropTypes.string.isRequired,
};
