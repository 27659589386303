/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SelectImageModal({
  show,
  onExit,
  onClickOkButton,
  images,
  setImages,
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [selectAllImageChecked, setSelectAllImageChecked] = useState(false);

  useEffect(() => {
    setSelectAllImageChecked(false);
  }, [show]);

  useEffect(() => {
    let NSelectedImages = 0;
    Object.keys(images).forEach((key) => {
      if (images[key].isSelected) NSelectedImages += 1;
    });
    if (NSelectedImages < 4) setDisabled(true);
    else setDisabled(false);
  }, [images]);

  return (
    <Modal
      show={show}
      onExit={() => {
        onExit();
      }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: '#5c636a', fontWeight: 'bold' }}
        >
          {t('解析したい画像を4枚以上選択')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll' }}>
        <Table striped className="selected-files-table">
          <thead>
            <tr>
              <th>{t('画像名')}</th>
              <th>
                {t('観測時刻')}({t('世界時')})
              </th>
              <th>{t('自動測定状況')}</th>
              <th>{t('手動測定状況')}</th>
              <th>{t('測定回数')}</th>
              <th>{t('選択')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('一括選択')}</td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={selectAllImageChecked}
                  onChange={() => {
                    setSelectAllImageChecked((prev) => {
                      const newImages = JSON.parse(JSON.stringify(images));
                      Object.keys(newImages).forEach((key) => {
                        newImages[key].isSelected = !prev;
                      });
                      setImages(newImages);
                      return !prev;
                    });
                  }}
                />
              </td>
            </tr>
            {Object.keys(images).map((key) => (
              <tr>
                <td>{key}</td>
                <td>{images[key].observedTime}</td>
                <td>
                  {images[key].isAutoMeasured ? t('解析済') : t('未解析')}
                </td>
                <td>
                  {images[key].isManualMeasured ? t('解析済') : t('未解析')}
                </td>
                <td>{images[key].analyzedCount}</td>
                <td>
                  <Form.Check
                    id={`check-${key}`}
                    checked={images[key].isSelected}
                    type="checkbox"
                    name="group1"
                    onChange={() => {
                      const newImages = JSON.parse(JSON.stringify(images));
                      newImages[key].isSelected = !newImages[key].isSelected;
                      setImages(newImages);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          onClick={onExit}
          className="btn-style box_border_blue justify-content-left"
        >
          {t('キャンセル')}
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            onClickOkButton(images);
            onExit();
          }}
          className="btn-style box_blue justify-content-right"
        >
          {disabled ? t('4枚以上選択してください') : t('決定')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectImageModal;

SelectImageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  onClickOkButton: PropTypes.func.isRequired,
  images: PropTypes.objectOf(PropTypes.object).isRequired,
  setImages: PropTypes.func.isRequired,
};
