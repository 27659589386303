import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MeasureAlertModal({ show, setShow }) {
  const { t, i18n } = useTranslation();
  const modalBodySentences =
    i18n.language === 'en' ? (
      <>
        You selected many many candidates (including noises), however, usually
        we cannot find such many real moving objects in an image. You do not
        seem to distinguish noises and real moving objects (how to distinguish:
        please see{' '}
        <a
          href="https://web-coias.u-aizu.ac.jp/guide"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>
        ). Please distinguish noises and moving objects once again and select
        only real moving objects. If you really believe that you selected only
        real moving objects, please send an e-mail with screenshots of Prepare
        and Search/Redraw modes to the COIAS development team.
      </>
    ) : (
      <>
        あなたは非常に多くの移動天体候補(ノイズを含む)を選択しましたが、通常は1枚の画像の中にそれほど多くの移動天体が見つかることはありません。ノイズと移動天体候補の選別ができていないものと思われます(選別方法は
        <a
          href="https://web-coias.u-aizu.ac.jp/guide"
          target="_blank"
          rel="noreferrer noopener"
        >
          こちら
        </a>
        )。もう一度ノイズと本物の移動天体候補を正しく選別し、移動天体候補だけを選択した状態にしてください。あなたが選んだものが本当に全て移動天体候補だと思われる場合は、「探索準備」画面および「探索/再描画」画面のスクリーンショットを添えて開発チームにメールでお知らせください。
      </>
    );

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('警告')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalBodySentences}</Modal.Body>

      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="primary"
          className="btn-style box_blue"
          onClick={() => {
            setShow(false);
          }}
        >
          {t('戻る')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MeasureAlertModal;

MeasureAlertModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
