import { React } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function LoadingButton({
  loading,
  processName,
  showProgress,
  lastJsonMessage,
  fileUploadProgress,
  showInterruptProcess,
  onClickInterruptProcess,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="button"
        style={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          zIndex: 100,
          backgroundColor: '#0000004f',
          top: 0,
          left: 0,
          display: loading ? 'block' : 'none',
          cursor: 'default',
        }}
      >
        <Spinner
          animation="border"
          style={{
            width: '50px',
            height: '50px',
          }}
        />
        <div id="current-process" className="f-ja">
          {t(processName)}...
        </div>
        <div
          id="current-progress"
          style={{ display: showProgress ? 'block' : 'none' }}
        >
          {processName === 'アップロード中'
            ? fileUploadProgress
            : lastJsonMessage && lastJsonMessage.progress}
        </div>
      </Button>
      {showInterruptProcess ? (
        <Button
          className="btn-style box_border_blue f-ja"
          style={{
            width: '10vw',
            position: 'fixed',
            top: '80vh',
            left: '45vw',
            zIndex: 110,
            display: loading ? 'block' : 'none',
          }}
          onClick={onClickInterruptProcess}
        >
          {t('処理中断')}
        </Button>
      ) : (
        ''
      )}
    </>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  processName: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  lastJsonMessage: PropTypes.objectOf(PropTypes.string),
  fileUploadProgress: PropTypes.string,
  showInterruptProcess: PropTypes.bool,
  onClickInterruptProcess: PropTypes.func,
};

LoadingButton.defaultProps = {
  showProgress: false,
  lastJsonMessage: {},
  fileUploadProgress: '',
  showInterruptProcess: false,
  onClickInterruptProcess: () => {},
};

export default LoadingButton;
