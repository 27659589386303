/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SelectDateModal({
  show,
  onExit,
  onClickOkButton,
  observedDates,
  setObservedDates,
  selectedDateIds,
  setSelectedDateIds,
  selectMultipleDates,
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (selectMultipleDates) {
      let NSelectedDates = 0;
      Object.keys(observedDates).forEach((key) => {
        if (observedDates[key].isSelected) NSelectedDates += 1;
      });
      setDisabled(NSelectedDates === 0);
    } else {
      setDisabled(selectedDateIds === undefined);
    }
  }, [observedDates, selectedDateIds]);
  return (
    <Modal
      show={show}
      onExit={() => {
        onExit();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: '#5c636a', fontWeight: 'bold' }}
        >
          {t('解析したい画像の観測日を選択')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'scroll' }}>
        <Table striped className="selected-files-table">
          <thead>
            <tr>
              <th>{t('観測日')}</th>
              <th>{t('枚数')}</th>
              <th>{t('自動測定率')}</th>
              <th>{t('手動測定率')}</th>
              <th>{t('選択')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(observedDates).map((key, index) => (
              <tr>
                <td>{key}</td>
                <td>{observedDates[key].n_total_images}</td>
                <td>{`${Math.floor(observedDates[key].progress * 100.0)}%`}</td>
                <td>{`${Math.floor(
                  observedDates[key].manual_progress * 100.0,
                )}%`}</td>
                <td>
                  {selectMultipleDates ? (
                    <Form.Check
                      id={`check-${index}`}
                      value={observedDates[key].dir_ids}
                      type="checkbox"
                      name="group1"
                      defaultChecked={false}
                      onChange={(e) => {
                        const newObservedDates = JSON.parse(
                          JSON.stringify(observedDates),
                        );
                        newObservedDates[key].isSelected = e.target.checked;
                        setObservedDates(newObservedDates);
                      }}
                    />
                  ) : (
                    <Form.Check
                      id={`radio-${index}`}
                      value={observedDates[key].dir_ids}
                      type="radio"
                      name="group2"
                      onChange={(e) => {
                        // valueに代入されている observeDates[key].dir_ids は数値の配列だが,
                        // Reactによってinput要素にエンコードされた段階で カンマ(,)で区切られた数値 の文字列に変化している
                        setSelectedDateIds(e.currentTarget.value.split(','));
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          onClick={onExit}
          className="btn-style box_border_blue justify-content-left"
        >
          {t('キャンセル')}
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            let tmpSelectedDateIds;
            if (selectMultipleDates) {
              tmpSelectedDateIds = [];
              Object.keys(observedDates).forEach((key) => {
                if (observedDates[key].isSelected) {
                  tmpSelectedDateIds = tmpSelectedDateIds.concat(
                    observedDates[key].dir_ids,
                  );
                }
              });
            } else {
              tmpSelectedDateIds = selectedDateIds;
            }
            onClickOkButton(tmpSelectedDateIds);
            onExit();
          }}
          className="btn-style box_blue justify-content-right"
        >
          {t('決定')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectDateModal;

SelectDateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  onClickOkButton: PropTypes.func.isRequired,
  observedDates: PropTypes.objectOf(PropTypes.object).isRequired,
  setObservedDates: PropTypes.func.isRequired,
  selectedDateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedDateIds: PropTypes.func.isRequired,
  selectMultipleDates: PropTypes.bool.isRequired,
};
