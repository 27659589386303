import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UserIDContext } from '../functional/context';

function ErrorModal({
  show,
  setShow,
  errorPlace,
  errorReason,
  onExit,
  setLoading,
}) {
  const { t, i18n } = useTranslation();

  const reactApiUri = process.env.REACT_APP_API_URI;
  const { userId } = useContext(UserIDContext);

  const downloadLogFIle = () => {
    axios
      .get(`${reactApiUri}log?user_id=${userId}`)
      .then((response) => response.data.result)
      .then((log) => {
        const file = new Blob(
          log.map((item) => `${item}\n`),
          {
            type: 'text/plain',
          },
        );
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = 'log.txt';
        document.body.appendChild(element);
        element.click();
      })
      .catch(() => {
        console.log(t('エラーが発生しました'));
      });
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} onExit={onExit} centered>
      <Modal.Header closeButton>
        <Modal.Title className="f-modal_title f-ja">
          {t('エラーが発生しました')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="f-ja">
        {errorPlace?.length === 0 || i18n.language === 'en'
          ? t('エラーが発生しました')
          : `${errorPlace}で${t('エラーが発生しました')}。`}
        <br />
        <br />
        {i18n.language === 'en'
          ? "Please send log.txt to the developers by an e-mail. You can download log.txt by clicking 'Download Log' button."
          : errorReason}
        <br />
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="btn-style box_blue f-ja"
          onClick={() => {
            downloadLogFIle();
          }}
        >
          {t('ログをダウンロード')}
        </Button>
        <Button
          className="btn-style box_border_blue f-ja"
          onClick={handleClose}
        >
          {t('閉じる')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ErrorModal;

ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  errorPlace: PropTypes.string.isRequired,
  errorReason: PropTypes.string.isRequired,
  onExit: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  onExit: () => {},
};
