import axios from 'axios';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ModeStatusContext,
  UserIDContext,
} from '../component/functional/context';
import AlertModal from '../component/general/AlertModal';
import ErrorModal from '../component/general/ErrorModal';
import LoadingButton from '../component/general/LoadingButton';
import ThankYouModal from '../component/model/Report/ThankYouModal';
import UtilFunc from '../utils/UtilFunc';

function Report({ setMenunames, setFileNames, setFileObservedTimes }) {
  const { t, i18n } = useTranslation();
  const { userId } = useContext(UserIDContext);
  const processing = useRef(false);

  const reactApiUri = process.env.REACT_APP_API_URI;
  const socketUrl = `${process.env.REACT_APP_WEB_SOCKET_URI}${userId}`;

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 3,
    reconnectInterval: 3000,
  });

  const [sendMpcMEA, setSendMpcMEA] = useState('');
  const [sendMpcBody, setSendMpcBody] = useState([]);
  const [sendMpc, setSendMpc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showProcessError, setShowProcessError] = useState(false);
  const [errorPlace, setErrorPlace] = useState('');
  const [errorReason, setErrorReason] = useState('');

  const [showProgress, setShowProgress] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const { modeStatus, setModeStatus } = useContext(ModeStatusContext);
  const [thankYouMessageBig, setThankYouMessageBig] = useState('');
  const [thankYouMessageSmall, setThankYouMessageSmall] = useState('');
  const [isSendMailMode, setIsSendMailMode] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/');
  };

  const [cookies, setCookie] = useCookies('coias_previous_MEA');

  const makeSendMpc = async () => {
    const header = [
      'COD T09',
      'CON S. Urakawa, [s_urakawa@spaceguard.or.jp], BSGC, Okayama, Japan',
      `OBS H. Aihara, Y. AlSayyad, M. Ando, R. Armstrong, J. Bosch, E. Egami,`,
      `OBS H. Furusawa, J. Furusawa, S. Harasawa, Y. Harikane, B.-C. Hsieh, H. Ikeda,`,
      `OBS K. Ito, I. Iwata, T. Kodama, M. Koike, M. Kokubo, Y. Komiyama, X. Li,`,
      `OBS Y. Liang, Y.-T. Lin, R. H. Lupton, N. B. Lust, L. A. MacArthur,`,
      `OBS K. Mawatari, S. Mineo, H. Miyatake, S. Miyazaki, S. More, T. Morishima,`,
      `OBS H. Murayama, K. Nakajima, F. Nakata, A. J. Nishizawa, M. Oguri, N. Okabe,`,
      `OBS Y. Okura, Y. Ono, K. Osato, M. Ouchi, Y.-C. Pan, A. A. Plazas Malag\\'on,`,
      `OBS P. A. Price, S. L. Reed, E. S. Rykoff, T. Shibuya, M. Simunovic,`,
      `OBS M. A. Strauss, K. Sugimori, Y. Suto, N. Suzuki, M. Takada, Y. Takagi,`,
      `OBS T. Takata, S. Takita, M. Tanaka, S. Tang, D. S. Taranu, T. Terai, Y. Toba,`,
      `OBS E. L. Turner, H. Uchiyama, B. Vijarnwannaluk, C. Z. Waters, Y. Yamada,`,
      `OBS N. Yamamoto, T. Yamashita`,
      `MEA ${sendMpcMEA}`,
      'TEL 8.2-m f/2.0 reflector + CCD(HSC)',
      'NET PS1-DR1',
      'ACK Subaru/HSC',
    ];

    await setSendMpc(header.concat(sendMpcBody));
  };

  const downloadSendMpcFile = () => {
    const element = document.createElement('a');
    const file = new Blob(
      sendMpc.map((item) => `${item}\n`),
      {
        type: 'text/plain',
      },
    );
    element.href = URL.createObjectURL(file);
    element.download = 'send_mpc.txt';
    document.body.appendChild(element);
    element.click();
  };

  const getMpc = async () => {
    setLoading(true);
    setShowProgress(true);
    await axios
      .put(
        modeStatus.FinalCheck
          ? `${reactApiUri}get_mpc`
          : `${reactApiUri}AstsearchR_afterReCOIAS`,
        null,
        {
          params: { user_id: userId },
        },
      )
      .then((response) => {
        const mpctext = response.data.send_mpc;
        const result = mpctext.split('\n');
        setSendMpcBody(
          result.map((item) => {
            const trimedStr = item.trim();
            const splitStr = trimedStr.split(' ');
            if (splitStr[0].length >= 7) {
              return `\u0020\u0020\u0020\u0020\u0020${trimedStr}`;
            }
            return trimedStr;
          }),
        );
        setModeStatus((prevModeStatus) => {
          const modeStatusCopy = { ...prevModeStatus };
          modeStatusCopy.FinalCheck = true;
          return modeStatusCopy;
        });
        setLoading(false);
      })
      .catch((e) => {
        const errorObject = UtilFunc.errorStringProcess(e.response?.data);
        setErrorPlace(errorObject.place);
        setErrorReason(errorObject.reason);
        setShowProcessError(true);
        setLoading(false);
      });
  };

  const downloadFinalAllFile = async () => {
    await axios
      .get(`${reactApiUri}final_all?user_id=${userId}`)
      .then((response) => response.data.finalall)
      .then((finalall) => {
        const finalAllArray = finalall.split('\n');
        const element = document.createElement('a');
        const file = new Blob(
          finalAllArray.map((content) => `${content}\n`),
          {
            type: 'text/plain',
          },
        );
        element.href = URL.createObjectURL(file);
        element.download = 'final_all.txt';
        document.body.appendChild(element);
        element.click();
      })
      .catch(() => {
        setLoading(false);
        setShowError(true);
        setErrorMessage('final_all.txtがありません');
      });
  };

  const downloadFiles = () => {
    if (sendMpcBody[0] === '') {
      setErrorMessage(
        `${t(
          'ダウンロードできるデータが存在しません。\n探索・手動測定モードに戻って有効な新天体を見つけるか、「確認完了・メール送信」ボタンを押して別の画像を測定してください。',
        )}`,
      );
      setShowError(true);
      return;
    }

    downloadSendMpcFile();
    downloadFinalAllFile();
  };

  const onClickSendMail = async () => {
    let bigMessage;
    let smallMessage;
    if (sendMpcBody[0] === '') {
      if (i18n.language === 'en') {
        bigMessage = 'Thank you for your measurement!';
        smallMessage =
          'Unfortunately, there is no measurement to be sent, however, "no-detection" is also important information.';
      } else {
        bigMessage = '測定ありがとうございました!';
        smallMessage =
          '残念ながら報告する情報はありませんでしたが,\n「検出できなかった」ことも重要な情報です。';
      }
      axios
        .put(`${reactApiUri}blank_postprocess`, null, {
          params: { user_id: userId },
        })
        .catch(() => {});
    } else {
      const wrongNamePattern = UtilFunc.checkValidMEANames(sendMpcMEA);
      if (sendMpcMEA.split(',')[0] === '') {
        setErrorMessage(
          t(
            'MEA欄に1人以上の有効な名前を入力してください\n(例) Y. Endo, M. Konohata, A. Manaka',
          ),
        );
        setShowError(true);
        return;
      }
      if (wrongNamePattern) {
        setErrorMessage(
          t(
            'MEA欄に有効な書式の名前を入力してください。\n有効な書式: [名前の頭文字大文字]. [苗字を頭文字だけ大文字]\n全て半角英字\n名前と名字の間にはピリオドと半角スペースを入力\n複数の場合はカンマで並べる\n(例) Y. Endo, M. Konohata, A. Manaka',
          ),
        );
        setShowError(true);
        return;
      }

      // processing.current === true ならば2重クリックされているということなので処理を抜ける
      if (processing.current) return;
      // 処理中フラグを上げる
      processing.current = true;
      // 十分に時間が経ったら処理中フラグを下げる
      setTimeout(() => {
        processing.current = false;
      }, 3000);

      let error = false;
      setLoading(true);
      setIsSendMailMode(true);
      await axios
        .put(`${reactApiUri}postprocess`, sendMpc, {
          params: { user_id: userId },
        })
        .then(async () => {
          const measuredNameList = [];
          sendMpcBody.forEach((line) => {
            const name = line.trim().split(' ')[0].replace('*', '');
            if (!measuredNameList.includes(name) && name.length !== 0)
              measuredNameList.push(name);
          });
          let NUnknownObjects = 0;
          let NKnownObjects = 0;
          measuredNameList.forEach((name) => {
            if (name.length === 7 && name.startsWith('H')) {
              NUnknownObjects += 1;
            } else {
              NKnownObjects += 1;
            }
          });
          bigMessage = 'あなたは';
          if (NUnknownObjects !== 0) {
            bigMessage = bigMessage.concat(`${NUnknownObjects}個の新天体候補`);
            if (NKnownObjects !== 0) {
              bigMessage = bigMessage.concat('と\n');
            }
          }
          if (NKnownObjects !== 0) {
            bigMessage = bigMessage.concat(
              `${NKnownObjects}${t('個の既知天体')}`,
            );
          }
          if (!(NUnknownObjects !== 0 && NKnownObjects !== 0)) {
            bigMessage = bigMessage.concat('\n');
          }
          bigMessage = bigMessage.concat('をMPCに報告しました!\n');
          if (i18n.language === 'en') {
            bigMessage = `You reported ${
              NUnknownObjects + NKnownObjects
            } objects to MPC!\n`;
          }

          const res2 = await axios
            .get(`${reactApiUri}N_new_objects`)
            .catch(() => {});
          smallMessage = '';
          if (res2 !== undefined && NUnknownObjects !== 0) {
            const NTotalNewObjects = res2.data.result;
            smallMessage = `COIASで発見された新天体候補は\n合計${NTotalNewObjects}個になりました。\n`;
            if (i18n.language === 'en') {
              smallMessage = `The number of new object candidates measured in COIAS becomes ${NTotalNewObjects} in total.`;
            }
          }
        })
        .catch((e) => {
          const errorObject = UtilFunc.errorStringProcess(e.response?.data);
          setErrorPlace(errorObject.place);
          setErrorReason(errorObject.reason);
          setShowProcessError(true);
          error = true;
        })
        .finally(() => {
          setLoading(false);
          setIsSendMailMode(false);
        });
      // postprocessの実行でエラーが出たら早期リターン
      if (error) return;
    }

    setThankYouMessageBig(bigMessage);
    setThankYouMessageSmall(smallMessage);
    setShowThankYouModal(true);
    setCookie('coias_previous_MEA', sendMpcMEA, {
      maxAge: 2 * 24 * 60 * 60,
    });
  };

  useEffect(() => {
    getMpc();
    // 前回レポート送信した時にセットされていたMEAを取得する
    if (cookies.coias_previous_MEA) {
      setSendMpcMEA(cookies.coias_previous_MEA);
    }
  }, []);

  useEffect(() => {
    makeSendMpc();
  }, [sendMpcMEA, sendMpcBody]);

  return (
    <div className="report-wrap">
      <Row xs="auto" className="report-wrap_form">
        <Col>
          <h4 className="f-modal_title f-ja">{t('測定者名')} </h4>
        </Col>
        <Col md={8}>
          <Form.Control
            style={{ textOverflow: 'ellipsis' }}
            placeholder={t(
              '測定者(ご自身)のお名前を記入してください. 複数の場合はカンマ区切りで記入. (例) Y. Endo, M. Konohata, A. Manaka',
            )}
            value={sendMpcMEA}
            onChange={(e) => {
              setSendMpcMEA(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row xs="auto">
        <Col>
          <h4 className="f-modal_title f-ja">{t('レポート')} </h4>
        </Col>
        <Col md={8}>
          <div
            style={{
              width: '100%',
              backgroundColor: 'black',
              height: '61vh',
              overflow: 'scroll',
            }}
          >
            <ul id="send-mpc" style={{ listStyleType: 'none', color: 'white' }}>
              {sendMpc.length > 0 &&
                sendMpc.map((item) => <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div>
            <span className="f-ja">
              {t(
                '注: 測定のうち誤差が大きいもの・他の測定と重複するもの・測定数が少ないものは弾かれています。',
              )}
            </span>
          </div>
          <div className="report-btn_wrap">
            <Button
              variant="primary"
              onClick={() => {
                getMpc();
              }}
              className="btn-style box_blue f-ja"
            >
              {t('レポート作成をやり直す')}
            </Button>
            <div className="btn_wrap-content">
              <span className="f-ja">{t('ファイルをダウンロード')}</span>
              <Button
                variant="primary"
                onClick={() => {
                  downloadFiles();
                }}
                className="btn-style box_blue"
                disabled={!modeStatus.FinalCheck}
              >
                send_mpc.txt and final_all.txt
              </Button>
            </div>
          </div>
          <div className="report-btn_wrap d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={() => {
                onClickSendMail();
              }}
              className="btn-style box_blue"
              disabled={!modeStatus.FinalCheck}
            >
              {`${t('確認完了・メール送信')}`}
            </Button>
          </div>
        </Col>
      </Row>
      <LoadingButton
        loading={loading}
        processName={isSendMailMode ? 'レポート送信中' : 'レポートデータ取得中'}
        showProgress={isSendMailMode ? false : showProgress}
        lastJsonMessage={lastJsonMessage}
      />

      <ThankYouModal
        thankYouModalShow={showThankYouModal}
        onClickOk={() => {
          setFileNames(['ファイルを選択してください']);
          setFileObservedTimes([]);
          setModeStatus({
            ExplorePrepare: false,
            COIAS: false,
            Manual: false,
            Report: false,
            FinalCheck: false,
          });
          handleNavigate();
          setMenunames((prevMenunames) =>
            prevMenunames.map((items) => {
              const objCopy = { ...items };
              objCopy.done = false;
              return objCopy;
            }),
          );
          setShowThankYouModal(false);
        }}
        thankYouMessageBig={thankYouMessageBig}
        thankYouMessageSmall={thankYouMessageSmall}
      />

      <AlertModal
        alertModalShow={showError}
        onClickOk={() => {
          setShowError(false);
        }}
        alertMessage={errorMessage}
        alertButtonMessage="OK"
        size="md"
      />

      <ErrorModal
        show={showProcessError}
        setShow={setShowProcessError}
        errorPlace={errorPlace}
        errorReason={errorReason}
        setLoading={setLoading}
      />
    </div>
  );
}

export default Report;

Report.propTypes = {
  setMenunames: PropTypes.func.isRequired,
  setFileNames: PropTypes.func.isRequired,
  setFileObservedTimes: PropTypes.func.isRequired,
};
