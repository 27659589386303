import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function MeasureConfirmModal({
  show,
  setShow,
  onClickOk,
  setDisable,
  disable,
}) {
  const { t, i18n } = useTranslation();
  const modalBodySentences =
    i18n.language === 'en' ? (
      <>
        Most black rectangles (automatically detected objects) are noises. Real
        moving objects have constant sizes of sources and constant speeds. You
        have to only select real moving objects and make it red (how to
        distinguish: please see{' '}
        <a
          href="https://web-coias.u-aizu.ac.jp/guide"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>
        ).
        <span style={{ fontWeight: 'bold' }}>
          {' '}
          Are you sure you really selected only real moving objects?
        </span>
      </>
    ) : (
      <>
        自動検出結果を表す黒い四角のほとんどはノイズです。本物の移動天体候補は一定の大きさの光源が一定速度で動いているものだけです。移動天体候補のみをクリックして赤くしてください(選別方法は
        <a
          href="https://web-coias.u-aizu.ac.jp/guide"
          target="_blank"
          rel="noreferrer noopener"
        >
          こちら
        </a>
        )。
        <span style={{ fontWeight: 'bold' }}>先に進んでもよろしいですか？</span>
      </>
    );

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('確認')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalBodySentences}</Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
          className="btn-style box_border_blue"
        >
          {t('いいえ')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onClickOk();
            setDisable(!disable);
            setShow(false);
          }}
          className="btn-style box_blue"
        >
          {t('はい')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MeasureConfirmModal;

MeasureConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onClickOk: PropTypes.func.isRequired,
  setDisable: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};
