import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ConfirmStarUpdateModal({ show, setShow, onClickOk }) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      onExit={() => {
        setShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="f-modal_title f-ja">
          {t('小惑星データの更新')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="f-ja">
        {t('小惑星データの更新には数分ほど時間がかかる場合があります。')}
        <br />
        {t('更新を実行しますか?')}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          className="btn-style box_border_blue f-ja"
          onClick={() => {
            setShow(false);
          }}
        >
          {t('キャンセル')}
        </Button>
        <Button
          variant="primary"
          className="btn-style box_blue f-ja"
          onClick={() => {
            onClickOk();
            setShow(false);
          }}
        >
          {t('更新する')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmStarUpdateModal;

ConfirmStarUpdateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onClickOk: PropTypes.func.isRequired,
};
