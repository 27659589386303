import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { UserIDContext } from '../component/functional/context';
import LoadingButton from '../component/general/LoadingButton';

function PrivateRoutes() {
  const [cookies] = useCookies('coias_token');
  const { userId, setUserId } = useContext(UserIDContext);
  const reactApiUri = process.env.REACT_APP_API_URI;
  const [isLoading, setIsLoading] = useState(true);

  const verifyToken = async () => {
    setIsLoading(true);
    await axios
      .post(`${reactApiUri}verify_token`, {
        token: cookies.coias_token.access_token,
      })
      .then((res) => {
        setUserId(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setUserId(null);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (cookies.coias_token) {
      verifyToken();
    } else {
      setUserId(null);
      setIsLoading(false);
    }
  }, []);

  let component = null;

  if (isLoading) {
    component = <LoadingButton loading={isLoading} processName="読み込み中" />;
  } else if (userId && 'coias_token' in cookies) {
    component = <Outlet />;
  } else {
    component = <Navigate to="/Login" />;
  }

  return component;
}

export default PrivateRoutes;
