/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enJson from './lang/en.json';

const swapFn = <T extends Record<string, S>, S extends string>(obj: T) => {
  const res = {} as any;
  Object.entries(obj).forEach(([key]) => {
    res[key] = key;
  });
  return res as { [K in keyof T as T[K]]: K };
};
const jaJson = swapFn(enJson);

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ja: {
    translation: jaJson,
  },
  en: {
    translation: enJson,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ja', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
