import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function AlertModal({
  alertModalShow,
  onClickOk,
  alertMessage,
  alertButtonMessage,
  size,
}) {
  const { t } = useTranslation();
  return (
    <Modal show={alertModalShow} size={size} backdrop="static">
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('エラー')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="f-ja" style={{ whiteSpace: 'pre-line' }}>
        {t(alertMessage)}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          variant="success"
          className="btn-style box_blue f-ja"
          onClick={() => onClickOk()}
        >
          {t(alertButtonMessage)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;

AlertModal.propTypes = {
  alertModalShow: PropTypes.bool.isRequired,
  onClickOk: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
  alertButtonMessage: PropTypes.string.isRequired,
  size: PropTypes.string,
};

AlertModal.defaultProps = {
  size: 'sm',
};
