import React from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function StellarGlobeSettingModal({
  show,
  onHide,
  selectMultipleDates,
  setSelectMultipleDates,
  selectImageMode,
  setSelectImageMode,
  showAutoProgress,
  setShowAutoProgress,
}) {
  const { t } = useTranslation();
  const [, setCookie] = useCookies(['coias_show_auto_progress']);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="stellar_globe_setting_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: '#5c636a', fontWeight: 'bold' }}
        >
          {t('設定')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('複数観測日の画像の一括選択可否')}</h4>
        <Row className="setting_modal-content_wrap">
          <Col>
            <Form.Check
              name="group1"
              label={t('不可')}
              type="radio"
              onChange={() => setSelectMultipleDates(false)}
              defaultChecked={!selectMultipleDates && 'true'}
            />
          </Col>
          <Col>
            <Form.Check
              name="group1"
              label={t('可能')}
              type="radio"
              onChange={() => setSelectMultipleDates(true)}
              defaultChecked={selectMultipleDates && 'true'}
            />
          </Col>
        </Row>
        <h4 style={{ paddingTop: '30px' }}>
          {t('自動測定率/手動測定率の表示切り替え')}
        </h4>
        <Row className="setting_modal-content_wrap">
          <Col>
            <Form.Check
              name="group_progress"
              label={t('自動測定率')}
              type="radio"
              onChange={() => {
                setShowAutoProgress(true);
                setCookie('coias_show_auto_progress', true, {
                  maxAge: 7 * 24 * 60 * 60,
                });
              }}
              checked={showAutoProgress}
            />
          </Col>
          <Col>
            <Form.Check
              name="group_progress"
              label={t('手動測定率')}
              type="radio"
              onChange={() => {
                setShowAutoProgress(false);
                setCookie('coias_show_auto_progress', false, {
                  maxAge: 7 * 24 * 60 * 60,
                });
              }}
              checked={!showAutoProgress}
            />
          </Col>
        </Row>
        <h4 style={{ paddingTop: '30px' }}>
          {t('画像選択モード')}/{t('鑑賞モードの切り替え')}
        </h4>
        <Row className="setting_modal-content_wrap">
          <Col>
            <Form.Check
              name="group2"
              label={t('画像選択モード')}
              type="radio"
              onChange={() => setSelectImageMode(true)}
              defaultChecked={selectImageMode && 'true'}
            />
          </Col>
          <Col>
            <Form.Check
              name="group2"
              label={t('鑑賞モード')}
              type="radio"
              onChange={() => setSelectImageMode(false)}
              defaultChecked={!selectImageMode && 'true'}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={onHide} className="btn-style box_border_blue">
          {t('閉じる')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StellarGlobeSettingModal;

StellarGlobeSettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectMultipleDates: PropTypes.bool.isRequired,
  setSelectMultipleDates: PropTypes.func.isRequired,
  selectImageMode: PropTypes.bool.isRequired,
  setSelectImageMode: PropTypes.func.isRequired,
  showAutoProgress: PropTypes.bool.isRequired,
  setShowAutoProgress: PropTypes.func.isRequired,
};
