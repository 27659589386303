import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

import { FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';
import {
  BiZoomIn,
  BiZoomOut,
  BiTime,
  BiMask,
  BiCurrentLocation,
  BiAddToQueue,
} from 'react-icons/bi';
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai';
import { ImBrightnessContrast, ImContrast } from 'react-icons/im';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CONSTANT from '../../utils/CONSTANTS';

function HelpModal({ show, onHide }) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="f-modal_title f-ja"
        >
          {t('ヘルプ')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered>
          <tbody className="help-modal-table">
            <tr>
              <th>
                <FaPlay
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('ブリンクを開始/停止します。')}
                {t('sキーでも同じことができます')}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>
                <FaStepBackward
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
                <FaStepForward
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('ページを移動します。')}
                {t('左右キーでも同じことができます')}
              </td>
            </tr>
            <tr>
              <th className="f-en">sec</th>
              <td className="f-ja">
                {t('ブリンク速度を調節できます。')}
                {t('入力欄をクリックしてプルダウンリストから速度を選べます')}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>
                <BiZoomIn
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
                <BiZoomOut
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('画像の拡大縮小をします。')}
                {t('上下キーでも同じことができます')}
              </td>
            </tr>
            <tr>
              <th>
                <BiMask
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('全ての画像のマスクあり/なしを一括で切り替えます。')}
                {t('mキーでも同じことができます')}
              </td>
            </tr>
            <tr>
              <th>
                <AiOutlineEye
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('天体の枠、天体番号を非表示にします')}
              </td>
            </tr>
            <tr>
              <th className="f-en">1</th>
              <td className="f-ja">
                {t('画像番号です。')}
                {t('現在の画像の番号は灰色の背景になります。')}
                {t('クリックすることでその画像に移動できます')}
              </td>
            </tr>
            <tr>
              <th>
                <AiFillSetting
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('設定画面を開きます。')}
                {t('画像の表示有無')}・{t('画像のマスクの有無')}・
                {t('手動測定モードでの拡大率')}・
                {t('オートセーブの有無を選べます')}
              </td>
            </tr>
            <tr>
              <th>
                <ImBrightnessContrast
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('画像の輝度を調節できる調節バーです')}
              </td>
            </tr>
            <tr>
              <th>
                <ImContrast
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('画像のコントラストを調節できる調節バーです')}
              </td>
            </tr>
            <tr>
              <th>
                <BiTime
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('表示している画像の撮影時刻(世界時)です')}
              </td>
            </tr>
            <tr>
              <th>
                <BiCurrentLocation
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t('マウスポインタの位置における画像のピクセル座標です')}
              </td>
            </tr>
            <tr>
              <th>
                <BiAddToQueue
                  size={CONSTANT.iconSize28px}
                  color={CONSTANT.btnColorGray}
                />
              </th>
              <td className="f-ja">
                {t(
                  '(手動測定のみ) 手動測定する天体を追加します。nキーでも同じことができます',
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={onHide} className="btn-style box_border_blue f-ja">
          {t('閉じる')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HelpModal;

HelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
