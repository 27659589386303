import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

function MaintenanceModal({ show }) {
  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton={false}>
        <Modal.Title className="f-modal_title f-ja">
          メンテナンス中 / Under Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="f-ja">
        <p>
          COIASはメンテナンス中です。終了まで新たな測定はできませんので、タブを閉じてお待ちください。今後の予定や進捗状況についてはCOIASのトップページや
          <a href="https://twitter.com/coias_t09">Xアカウント</a>
          でお知らせします。
        </p>
        <br />
        <p>
          COIAS is under maintenance. Since you cannot start a new measurement
          until the end of maintenance, please close this tab. Updates will be
          posted on the COIAS website and the{' '}
          <a href="https://twitter.com/coias_t09">X account</a>.
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default MaintenanceModal;

MaintenanceModal.propTypes = {
  show: PropTypes.bool.isRequired,
};
