import React, { useContext } from 'react';
import axios from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserIDContext } from '../component/functional/context';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const API_URL = process.env.REACT_APP_API_URI;

function LoginPage() {
  const { t } = useTranslation();

  const location = useLocation();
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  };
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies('coias_token');

  const { userId } = useContext(UserIDContext);

  if (userId) {
    return <Navigate to={currentLocationState?.from.pathname} />;
  }

  const refreshToken = async () => {
    if (
      cookies &&
      Object.keys(cookies).length === 0 &&
      Object.getPrototypeOf(cookies) === Object.prototype
    ) {
      window.location.replace(REDIRECT_URL);
    } else {
      await axios
        .post(`${API_URL}refresh_token`, {
          refresh_token: cookies.coias_token.refresh_token,
        })
        .then((response) => {
          setCookie('coias_token', response.data, {
            path: '/',
            expires: new Date(response.data.expires_in_absolute_date),
            maxAge: response.data.expires_in + response.data.refresh_expires_in,
          });
          navigate('/');
        })
        .catch((e) => {
          console.error(e);
          removeCookie('coias_token');
          window.location.replace(REDIRECT_URL);
        });
    }
  };

  return (
    <div
      style={{ height: '10%', width: '30%', position: 'fixed', top: '15vh' }}
    >
      <Row>
        <h1 className="text-center">COIAS</h1>
      </Row>
      <Row>
        <img alt="" src="/icon.png" />
      </Row>
      <Row>
        <p style={{ color: 'red' }}>
          {t('接続できませんでした')}.<br />
          {t('以下のボタンより再接続をお願いします')}.<br />
          {t('再接続に失敗した場合はログインページに戻ります')}.
        </p>
      </Row>
      <Row>
        <Button
          onClick={() => {
            refreshToken();
          }}
        >
          {t('再接続')}
        </Button>
      </Row>
    </div>
  );
}

export default LoginPage;
