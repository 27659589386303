/*
 * 画像選択モード
 *
 */
import PropTypes from 'prop-types';
import axios from 'axios';
import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { useCookies } from 'react-cookie';
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import { BiHelpCircle } from 'react-icons/bi';
import { BsTranslate } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { AiFillFile, AiFillSetting } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
/* eslint-disable no-unused-vars */
import { angle, easing, Globe, SkyCoord } from '@stellar-globe/stellar-globe';
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved, import/extensions, no-unused-vars */
import {
  CelestialText,
  Constellation,
  Ecliptic,
  Equatorial,
  EsoMilkyWay,
  HipparcosCatalog,
  Patch,
  PatchSelector,
  RingsTract,
  SspData,
  SspOutline,
  StellarGlobe,
  Tract,
  TractSelector,
  PrettyPictures,
} from '../component/StellarGlobe';
/* eslint-disable import/no-unresolved, import/extensions, no-unused-vars */
import CONSTANT from '../utils/CONSTANTS';
import SelectDateModal from '../component/StellarGlobe/SelectDateModal';
import SelectImageModal from '../component/StellarGlobe/SelectImageModal';
import AutoSelectResultModal from '../component/StellarGlobe/AutoSelectResultModal';
import StellarGlobeSettingModal from '../component/StellarGlobe/StellarGlobeSettingModal';
import StellarGlobeHelpModal from '../component/StellarGlobe/StellarGlobeHelpModal';
import ColorLegend from '../component/StellarGlobe/ColorLegend';
import ErrorModal from '../component/general/ErrorModal';
import AlertModal from '../component/general/AlertModal';
import LoadingButton from '../component/general/LoadingButton';
import AgreeModal from '../component/StellarGlobe/AgreeModal';
import MaintenanceModal from '../component/general/MaintenanceModal';
import {
  ModeStatusContext,
  UserIDContext,
} from '../component/functional/context';
import UtilFunc from '../utils/UtilFunc';

function DataSelector({
  setFileNames,
  setFileObservedTimes,
  statusIntervalRef,
  showAutoProgress,
  setShowAutoProgress,
}) {
  const { t, i18n } = useTranslation();
  const reactApiUri = process.env.REACT_APP_API_URI;
  const { setModeStatus } = useContext(ModeStatusContext);
  const { userId } = useContext(UserIDContext);

  const [cookies, setCookie] = useCookies([
    'coias_previous_tract_id',
    'coias_show_auto_progress',
  ]);

  const globeRef = useRef(null);
  /** @type { () => Globe } */
  const globe = () => globeRef.current?.globe();

  const [helpModalShow, setHelpModalShow] = useState(false);
  const [settingModalShow, setSettingModalShow] = useState(false);
  const [selectMultipleDates, setSelectMultipleDates] = useState(false);
  const [selectImageMode, setSelectImageMode] = useState(true);
  const [selectDateModalShow, setSelectDateModalShow] = useState(false);
  const [selectImageModalShow, setSelectImageModalShow] = useState(false);
  const [fileSelectState, setFileSelectState] = useState('未選択');
  const [images, setImages] = useState([]);
  const [tracts, setTracts] = useState([]);
  const [validPatchIds, setValidPatchIds] = useState([]);
  const [validPatchProgresses, setValidPatchProgresses] = useState([]);
  const [observedDates, setObservedDates] = useState({});
  const [selectedTractId, setSelectedTractId] = useState(undefined);
  const [selectedPatchId, setSelectedPatchId] = useState(undefined);
  const [selectedDateIds, setSelectedDateIds] = useState(undefined);
  const [autoSelectResult, setAutoSelectResult] = useState({});
  const [showAutoSelectResult, setShowAutoSelectResult] = useState(false);
  const [NMyObject, setNMyObject] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorPlace, setErrorPlace] = useState('');
  const [errorReason, setErrorReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [fovy, setFovy] = useState(1);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtonMessage, setAlertButtonMessage] = useState('');
  const [agreeModalShow, setAgreeModalShow] = useState(false);
  const [maintenanceModalShow, setMaintenanceModalShow] = useState(false);
  const [previousTractId, setPreviousTractId] = useState(0);
  const ringsTract = new RingsTract();

  const TRACT_PATCH_COLORS = useMemo(() => [
    {
      color: [1, 0, 0],
      comment: '未解析',
    },
    { color: [1, 0.65, 0], comment: '解析率中' },
    { color: [1, 1, 0], comment: '解析率大' },
    { color: [0, 1, 0], comment: '解析完了' },
    { color: [0, 1, 1], comment: '選択中' },
  ]);

  const defaultStyle = useMemo(
    () => ({
      baseColor: [1, 0, 0, 1],
      hoverColor: [1, 0, 0, 1],
      activeColor: [1, 0, 0, 1],
      baseFillColor: [1, 0, 0, 1],
    }),
    [],
  );

  const selectedStyle = useMemo(
    () => ({
      baseColor: TRACT_PATCH_COLORS[4].color.concat([1]),
      baseFillColor: TRACT_PATCH_COLORS[4].color.concat([0.5]),
    }),
    [],
  );

  const proggressDependentStyle = (tmpProgress) => {
    let color;
    if (tmpProgress < 0.33) {
      color = TRACT_PATCH_COLORS[0].color;
    } else if (tmpProgress < 0.66) {
      color = TRACT_PATCH_COLORS[1].color;
    } else if (tmpProgress < 0.99) {
      color = TRACT_PATCH_COLORS[2].color;
    } else {
      color = TRACT_PATCH_COLORS[3].color;
    }
    const style = {
      ...defaultStyle,
      baseColor: color.concat([1]),
      hoverColor: color.concat([1]),
      baseFillColor: color.concat([0.5]),
    };
    return style;
  };

  const progressColoredPatch = useMemo(() => {
    /** @type {import('../../component/StellarGlobe/TractPatch').PatchSelectorProps["patchStyle"]} */
    const patchStyle = {};
    validPatchProgresses.forEach((validPatchProgress) => {
      const { progress, manualProgress } = validPatchProgress;
      patchStyle[validPatchProgress.patchIdStr] = showAutoProgress
        ? proggressDependentStyle(progress)
        : proggressDependentStyle(manualProgress);
    });
    return patchStyle;
  }, [validPatchProgresses, showAutoProgress]);

  const REGION_NAMES = useMemo(() => {
    const names = [
      '',
      'Area 1',
      'Area 2',
      'Area 3',
      'Area 1 Deep A',
      'Area 1 Deep B',
      'Area 2 Deep A',
      'Area 3 Deep A',
    ];
    return names;
  }, [i18n.language]);

  /** @type {import('../../component/StellarGlobe/layers').CelestialTextProps} */
  const textProp = useMemo(() => {
    const textArray = [
      {
        position: SkyCoord.fromDeg(90, 23.4).xyz,
        text: t('黄道'),
        color: 'orange',
      },
    ];
    if (selectImageMode) {
      textArray.push({
        position: SkyCoord.fromDeg(5, 8.5).xyz,
        text: t(REGION_NAMES[1]),
        color: 'cyan',
        font: '50px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(177, 8).xyz,
        text: t(REGION_NAMES[2]),
        color: 'cyan',
        font: '50px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(225, 47).xyz,
        text: t(REGION_NAMES[3]),
        color: 'cyan',
        font: '50px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(-9, -5).xyz,
        text: t(REGION_NAMES[4]),
        color: 'violet',
        font: '25px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(35, -9.5).xyz,
        text: t(REGION_NAMES[5]),
        color: 'violet',
        font: '25px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(150, 7).xyz,
        text: t(REGION_NAMES[6]),
        color: 'violet',
        font: '25px serif',
      });
      textArray.push({
        position: SkyCoord.fromDeg(241, 51).xyz,
        text: t(REGION_NAMES[7]),
        color: 'violet',
        font: '25px serif',
      });
    } else {
      textArray.push({
        position: SkyCoord.fromDeg(90, 0).xyz,
        text: t('赤道'),
        color: 'red',
      });
      textArray.push({
        position: SkyCoord.fromDeg(0, 90).xyz,
        text: t('天の北極'),
      });
      textArray.push({
        position: SkyCoord.fromDeg(0, 0).xyz,
        text: t('春分点'),
        color: 'fuchsia',
      });
      textArray.push({
        position: SkyCoord.fromDeg(180, 0).xyz,
        text: t('秋分点'),
        color: 'crimson',
      });
      textArray.push({
        position: SkyCoord.fromDeg(0, -90).xyz,
        text: t('天の南極'),
      });
    }
    const textObject = {
      billboardTexts: textArray,
      defaultColor: 'white',
      defaultFont: '25px serif',
      alpha: 1,
    };
    return textObject;
  }, [selectImageMode, i18n.language]);

  const textTrackNumberPropArray = useMemo(() => {
    const textObjectArray = [];
    const tractsLength = tracts?.length;

    let textArray = [];
    tracts.forEach((tract, index) => {
      const [a, d] = ringsTract.index2ad(tract.id);
      textArray.push({
        position: SkyCoord.fromRad(a, d).xyz,
        text: tract.id,
        color: 'white',
        font: '10px serif',
      });

      /*
      CelestialTextコンポーネントにtextObjectを与える時, billboardTextsの配列の数が概ね500を超えるとWebGLにエラーを吐かれる.
      そのため200個ごとくらいに小分けにする.
      */
      if ((index + 1) % 200 === 0 || index + 1 === tractsLength) {
        const textObject = {
          billboardTexts: textArray,
          defaultColor: 'white',
          defaultFont: '25px serif',
          alpha: 1,
        };
        textObjectArray.push(textObject);
        textArray = [];
      }
    });

    return textObjectArray;
  }, [tracts]);

  const BEAUTIFUL_OBJECTS = useMemo(() => {
    const obj = {
      objectsInOurGalaxy: [
        {
          name: `${t('プレアデス星団')} (M45 ${t('和名')}「すばる」)`,
          coord: '03:47:00 +24:07:05',
          fovyDeg: 0.5,
        },
        {
          name: `${t('オリオン星雲')} (M42)`,
          coord: '05:35:17 -05:23:28',
          fovyDeg: 0.5,
        },
      ],
      galaxies: [
        {
          name: `${t('アンドロメダ銀河')} (M31)`,
          coord: '00:42:44 +41:16:9',
          fovyDeg: 0.5,
        },
        {
          name: `${t('矮小銀河')} KKR25`,
          coord: '16:13:48 +54:22:16',
          fovyDeg: 0.03,
        },
        {
          name: `${t('渦巻銀河')} M101`,
          coord: '14:03:13 +54:20:56',
          fovyDeg: 0.3,
        },
        {
          name: `${t('おたまじゃくし銀河')} (UGC 10214)`,
          coord: '16:06:03.9 +55:25:32',
          fovyDeg: 0.04,
        },
        {
          name: `${t('衝突銀河')} UGC 12589 & 2MASX J23250382+0001068`,
          coord: '23:25:01.7 +00:00:01.9',
          fovyDeg: 0.03,
        },
        {
          name: `${t('衝突銀河')} 2MASX J16270254+4328340`,
          coord: '16:27:02.5 +43:28:34.1',
          fovyDeg: 0.03,
        },
        {
          name: `${t('衝突銀河')} LEDA 2535615 & LEDA 2535506`,
          coord: '16:14:53.4 +56:24:08.8',
          fovyDeg: 0.02,
        },
        {
          name: `${t('衝突銀河')} NGC 7667`,
          coord: '23:24:22.8 -00:06:30.2',
          fovyDeg: 0.03,
        },
        {
          name: `${t('衝突銀河')} UGC 9327 & FIRST J143043.0+001510`,
          coord: '14:30:43.0 +00:15:10.7',
          fovyDeg: 0.03,
        },
      ],
      gravityLens: [
        {
          name: `${t('ホルスの目')}`,
          coord: '14:24:49 -00:53:22',
          fovyDeg: 0.0075,
        },
        {
          name: `${t('重力レンズ')} SDSS J115214.19+003126.5`,
          coord: '11:52:14.19 +00:31:26.5',
          fovyDeg: 0.03,
        },
        {
          name: `${t('重力レンズ')} NVSS J142016+005718`,
          coord: '14:20:16.6 +00:57:19',
          fovyDeg: 0.03,
        },
        {
          name: `${t('重力レンズ')} 2SLAQ J144132.67-005358.3`,
          coord: '14:41:32.67 -00:53:58.3',
          fovyDeg: 0.03,
        },
        {
          name: `${t('重力レンズ')} PMN J2329-0121`,
          coord: '23:29:47.9 -01:20:49',
          fovyDeg: 0.01,
        },
      ],
      galaxyCluster: [
        {
          name: `${t('ペルセウス座銀河団')}`,
          coord: '03:19:47.2 +41:30:47',
          fovyDeg: 0.5,
        },
      ],
    };
    return obj;
  }, [i18n.language]);

  useEffect(async () => {
    // モード初期化
    setModeStatus({
      ExplorePrepare: false,
      COIAS: false,
      Manual: false,
      Report: false,
      FinalCheck: false,
    });

    // このユーザの新規プロジェクトディレクトリを作成
    await axios
      .put(`${reactApiUri}make_pj_directory`, null, {
        params: {
          user_id: userId,
        },
      })
      .catch(() => {});

    // このユーザが測定した数を表示
    const resMyObj = await axios.get(
      `${reactApiUri}my_object_number?user_id=${userId}`,
    );
    if (resMyObj !== undefined) {
      setNMyObject(resMyObj.data.result.my_object_number);
    }

    // メンテナンス中などアプリを利用して欲しくない状態だったらメンテナンス中モーダルを出す
    async function checkIsMaintenance() {
      const isMaintenance = await axios
        .get(`${reactApiUri}status`)
        .catch((e) => {
          console.log(e);
        });
      if (isMaintenance && !isMaintenance?.data?.is_active) {
        setMaintenanceModalShow(true);
      }
    }
    checkIsMaintenance();
    if (statusIntervalRef.current === null) {
      // eslint-disable-next-line no-param-reassign
      statusIntervalRef.current = setInterval(() => {
        checkIsMaintenance();
      }, 10000);
    }

    // このユーザが使い方ガイドを読んで利用規程に同意したか調べ, まだだったら同意モーダルを出す
    const resAgreed = await axios
      .get(`${reactApiUri}agreed?user_id=${userId}`)
      .catch((e) => {
        console.log(e);
      });
    if (!resAgreed.data.result) {
      setAgreeModalShow(true);
    }

    // 前回選択したtractIdをクッキーから取得
    const tmpPreviousTractId = parseInt(cookies.coias_previous_tract_id, 10);
    if (Number.isInteger(tmpPreviousTractId)) {
      setPreviousTractId(tmpPreviousTractId);
    }

    // 自動測定率表示/手動測定率表示をクッキーから取得
    if (
      cookies.coias_show_auto_progress === 'true' ||
      cookies.coias_show_auto_progress === 'false'
    ) {
      setShowAutoProgress(cookies.coias_show_auto_progress === 'true');
    }

    // StellarGlobeを操作した時に呼ばれるコールバック
    globe()?.on('camera-move-end', () => {
      setFovy(globe().camera.fovy);
    });
  }, []);

  // tractの一覧とその解析率を取得
  useEffect(async () => {
    // tractの一覧を取得
    const res = await axios
      .get(`${reactApiUri}tract_list?user_id=${userId}`)
      .catch((e) => {
        const errorObject = UtilFunc.errorStringProcess(e.response?.data);
        setErrorPlace(errorObject.place);
        setErrorReason(errorObject.reason);
        setShowErrorModal(true);
      });
    if (res !== undefined) {
      setTracts(
        Object.keys(res.data.result).map((key) => {
          const tractId = parseInt(key, 10);
          const progress = showAutoProgress
            ? res.data.result[key].progress
            : res.data.result[key].manual_progress;
          /** @type {import('../../component/StellarGlobe/TractPatch').TractSelectorTract} */
          const tractDef = {
            id: tractId,
            style: proggressDependentStyle(progress),
          };
          return tractDef;
        }),
      );
    }
  }, [showAutoProgress]);

  const getAndSetValidPatchIds = useCallback(async (tmpTractId) => {
    const res = await axios
      .get(`${reactApiUri}patch_list?tractId=${tmpTractId}&user_id=${userId}`)
      .catch((e) => {
        const errorObject = UtilFunc.errorStringProcess(e.response?.data);
        setErrorPlace(errorObject.place);
        setErrorReason(errorObject.reason);
        setShowErrorModal(true);
      });
    if (res !== undefined) {
      setValidPatchIds(
        Object.keys(res.data.result).map((tractPatchStr) => {
          const patchIdStr = tractPatchStr.split('-')[1];
          const patchIdStrSplitted = patchIdStr.split(',');
          const j = parseInt(patchIdStrSplitted[0], 10);
          const i = parseInt(patchIdStrSplitted[1], 10);
          return [j, i];
        }),
      );
      setValidPatchProgresses(
        Object.keys(res.data.result).map((tractPatchStr) => {
          const patchIdStr = tractPatchStr.split('-')[1];
          const { progress } = res.data.result[tractPatchStr];
          const manualProgress = res.data.result[tractPatchStr].manual_progress;
          return { patchIdStr, progress, manualProgress };
        }),
      );
    }
  }, []);

  const goRegion1 = useCallback(() => {
    globe().camera.jumpTo(
      {
        fovy: angle.deg2rad(25),
      },
      {
        coord: SkyCoord.fromDeg(5, 0),
        duration: 400,
        easingFunction: easing.fastStart4,
      },
    );
  }, []);

  const goRegion2 = useCallback(() => {
    globe().camera.jumpTo(
      {
        fovy: angle.deg2rad(35),
      },
      {
        coord: SkyCoord.fromDeg(177, 0),
        duration: 400,
        easingFunction: easing.fastStart4,
      },
    );
  }, []);

  const goRegion3 = useCallback(() => {
    globe().camera.jumpTo(
      {
        fovy: angle.deg2rad(15),
      },
      {
        coord: SkyCoord.fromDeg(225, 45),
        duration: 400,
        easingFunction: easing.fastStart4,
      },
    );
  }, []);

  const goThisObject = useCallback((thisObject) => {
    globe().camera.jumpTo(
      {
        fovy: angle.deg2rad(thisObject.fovyDeg),
      },
      {
        coord: SkyCoord.parse(thisObject.coord),
        duration: 400,
        easingFunction: easing.fastStart4,
      },
    );
  }, []);

  const goAllSky = useCallback(() => {
    globe().camera.jumpTo(
      {
        fovy: 1,
      },
      {
        coord: SkyCoord.fromDeg(0, 0),
      },
    );
  }, []);

  const goThisTract = useCallback((thisTractId) => {
    const [a, d] = ringsTract.index2ad(thisTractId);
    globe().camera.jumpTo(
      {
        fovy: angle.deg2rad(1),
      },
      {
        coord: SkyCoord.fromRad(a, d),
        duration: 400,
        easingFunction: easing.fastStart4,
      },
    );
  }, []);

  const tractOnClick = useCallback(async (tractIndex) => {
    setSelectedTractId(tractIndex);
    setSelectedPatchId(undefined);

    goThisTract(tractIndex);
    getAndSetValidPatchIds(tractIndex);
  }, []);

  const patchOnClick = useCallback(
    async (patchId) => {
      setSelectedPatchId(patchId);
      const tractPatchStr = `${selectedTractId}-${patchId}`;
      const res = await axios
        .get(
          `${reactApiUri}observe_date_list?patchId=${tractPatchStr}&user_id=${userId}`,
        )
        .catch((e) => {
          const errorObject = UtilFunc.errorStringProcess(e.response?.data);
          setErrorPlace(errorObject.place);
          setErrorReason(errorObject.reason);
          setShowErrorModal(true);
        });
      if (res !== undefined) {
        const pairs = Object.entries(res.data.result);
        pairs.sort((p1, p2) => {
          const p1Key = p1[0];
          const p2Key = p2[0];
          if (p1Key > p2Key) {
            return -1;
          }
          if (p1Key < p2Key) {
            return 1;
          }
          return 0;
        });
        const sortedDates = Object.fromEntries(pairs);
        Object.keys(sortedDates).forEach((key) => {
          sortedDates[key].isSelected = false;
        });

        setObservedDates(sortedDates);
        setSelectedDateIds(undefined);
        setSelectDateModalShow(true);
      }
    },
    [selectedTractId],
  );

  const dateOnClick = useCallback(async (dateIds) => {
    const dateIdsListStr = dateIds.map((dateId) => String(dateId));
    const dateIdsStr = dateIdsListStr.join('-');
    const res = await axios
      .get(`${reactApiUri}image_list?dirIdsStr=${dateIdsStr}&user_id=${userId}`)
      .catch((e) => {
        const errorObject = UtilFunc.errorStringProcess(e.response?.data);
        setErrorPlace(errorObject.place);
        setErrorReason(errorObject.reason);
        setShowErrorModal(true);
      });
    if (res !== undefined) {
      const pairs = Object.entries(res.data.result);
      pairs.sort((p1, p2) => {
        const p1Key = p1[0];
        const p2Key = p2[0];

        const p1Visit = parseInt(p1Key.split(/-|\./)[5], 10);
        const p2Visit = parseInt(p2Key.split(/-|\./)[5], 10);

        if (p1Visit < p2Visit) {
          return -1;
        }
        if (p1Visit > p2Visit) {
          return 1;
        }
        return 0;
      });
      const sortedImages = Object.fromEntries(pairs);
      Object.keys(sortedImages).forEach((key) => {
        sortedImages[key].isSelected = false;
      });

      setImages(sortedImages);
      setSelectImageModalShow(true);
    }
  }, []);

  const imageOnClick = useCallback(
    async (callBackImages) => {
      let NSelectedImages = 0;
      const tmpSelectedImageNames = [];
      const tmpSelectedImageTimes = [];
      Object.keys(callBackImages).forEach((key) => {
        if (callBackImages[key].isSelected) {
          NSelectedImages += 1;
          tmpSelectedImageNames.push(key);
          tmpSelectedImageTimes.push(callBackImages[key].observedTime);
        }
      });
      setFileSelectState(`×${NSelectedImages}`);
      setFileNames(tmpSelectedImageNames);
      setFileObservedTimes(tmpSelectedImageTimes);
      setCookie('coias_previous_tract_id', selectedTractId, {
        maxAge: 7 * 24 * 60 * 60,
      });

      setLoading(true);
      await axios
        .put(`${reactApiUri}put_image_list`, tmpSelectedImageNames, {
          params: {
            user_id: userId,
          },
        })
        .then((r) => {
          setModeStatus((prevModeStatus) => {
            const modeStatusCopy = { ...prevModeStatus };
            modeStatusCopy.ExplorePrepare = true;
            return modeStatusCopy;
          });
        })
        .catch((e) => {
          const errorObject = UtilFunc.errorStringProcess(e.response?.data);
          setErrorPlace(errorObject.place);
          setErrorReason(errorObject.reason);
          setShowErrorModal(true);
        })
        .finally(() => setLoading(false));
    },
    [selectedTractId],
  );

  const autoSelect = useCallback(async () => {
    const res = await axios
      .get(
        `${reactApiUri}${
          reactApiUri.startsWith('http://localhost:') ? 'test' : 'suggested'
        }_images?user_id=${userId}`,
      )
      .catch((e) => {
        const errorObject = UtilFunc.errorStringProcess(e.response?.data);
        const errorStatus = e.response?.status;

        if (errorStatus === 404) {
          setAlertModalShow(true);
          setAlertMessage(
            '自動選択できる画像がありません。手動選択を行ってください。',
          );
          setAlertButtonMessage('戻る');
        } else {
          setErrorPlace(errorObject.place);
          setErrorReason(errorObject.reason);
          setShowErrorModal(true);
        }
      });

    if (res !== undefined) {
      const resResult = res.data.result;
      let regionName;
      if (resResult.dec > 30) {
        [, , , regionName] = REGION_NAMES;
      } else if (resResult.ra > 120 && resResult.ra < 240) {
        [, , regionName] = REGION_NAMES;
      } else {
        [, regionName] = REGION_NAMES;
      }
      const tmpTractId = parseInt(resResult.tractPatch.split('-')[0], 10);
      const tmpPatchId = resResult.tractPatch.split('-')[1];
      setSelectedTractId(tmpTractId);
      setSelectedPatchId(tmpPatchId);
      getAndSetValidPatchIds(tmpTractId);

      goThisTract(tmpTractId);

      resResult.regionName = regionName;
      setAutoSelectResult(resResult);
      setShowAutoSelectResult(true);

      setFileSelectState(`×${resResult.warpFiles.length}`);
      setCookie('coias_previous_tract_id', tmpTractId, {
        maxAge: 7 * 24 * 60 * 60,
      });

      const fileNames = [];
      const fileObservedTimes = [];
      resResult.warpFiles.forEach((warpFile) => {
        fileNames.push(warpFile.fileName);
        fileObservedTimes.push(warpFile.observedTime);
      });
      setFileNames(fileNames);
      setFileObservedTimes(fileObservedTimes);

      setLoading(true);
      await axios
        .put(`${reactApiUri}put_image_list`, fileNames, {
          params: {
            user_id: userId,
          },
        })
        .then((r) => {
          setModeStatus((prevModeStatus) => {
            const modeStatusCopy = { ...prevModeStatus };
            modeStatusCopy.ExplorePrepare = true;
            return modeStatusCopy;
          });
        })
        .catch((e) => {
          const errorObject = UtilFunc.errorStringProcess(e.response?.data);
          setErrorPlace(errorObject.place);
          setErrorReason(errorObject.reason);
          setShowErrorModal(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const clearImageSelect = () => {
    setFileSelectState('未選択');
    setSelectedTractId(undefined);
    setSelectedPatchId(undefined);
    setSelectedDateIds(undefined);
    setFileNames([]);
    goRegion1();
    setModeStatus((prevModeStatus) => {
      const modeStatusCopy = { ...prevModeStatus };
      modeStatusCopy.ExplorePrepare = false;
      return modeStatusCopy;
    });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Row style={{ margin: '10px' }}>
        {selectImageMode ? (
          <>
            <Col style={{ margin: 'auto 0' }} className="d-flex">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  width: '26vw',
                }}
              >
                <h4 style={{ margin: 'auto 0' }}>{t('移動')}</h4>
                {/* 画像選択モード */}
                <ButtonGroup
                  aria-label="Basic example"
                  style={{ margin: 'auto 0', marginLeft: '10px' }}
                >
                  <Button onClick={goRegion1} className="btn-style box_blue">
                    Area 1
                  </Button>
                  <Button
                    onClick={goRegion2}
                    style={{
                      borderLeft: 'solid 1px #FFF',
                    }}
                    className="btn-style box_blue"
                  >
                    Area 2
                  </Button>
                  <Button
                    style={{
                      borderLeft: 'solid 1px #FFF',
                    }}
                    onClick={goRegion3}
                    className="btn-style box_blue"
                  >
                    Area 3
                  </Button>
                  <Button
                    style={{
                      marginLeft: '5px',
                    }}
                    disabled={previousTractId === 0}
                    onClick={() => {
                      tractOnClick(previousTractId);
                    }}
                    className="btn-style box_blue"
                  >
                    {t('前回')}
                  </Button>
                </ButtonGroup>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  width: '26vw',
                }}
              >
                <h4 style={{ margin: 'auto 0', marginLeft: '10px' }}>
                  {i18n.language === 'en' ? 'Image' : '画像'}
                </h4>

                <Button
                  onClick={autoSelect}
                  style={{
                    height: '40px',
                    margin: 'auto 0',
                    marginLeft: '10px',
                  }}
                  className="btn-style box_blue"
                >
                  {t('自動選択')}
                </Button>

                <div className="d-flex">
                  <AiFillFile
                    size={25}
                    style={{
                      margin: 'auto 0',
                      marginLeft: '10px',
                      color: '#5c636a',
                    }}
                  />
                  <h4 style={{ margin: 'auto 0' }}>{t(fileSelectState)}</h4>
                </div>
                <Button
                  onClick={clearImageSelect}
                  className="btn-style box_blue justify-content-end"
                  disabled={fileSelectState === '未選択'}
                  style={{
                    height: '40px',
                    margin: 'auto 0',
                    marginLeft: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MdDeleteForever size={25} />
                </Button>
              </div>
            </Col>
            <Col className="d-flex">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  width: '28vw',
                }}
              >
                <h4
                  className="f-en n-measure-object"
                  style={{ margin: 'auto 0' }}
                >
                  {t('あなたの測定天体数: ')} {NMyObject} {t('個')}
                </h4>
              </div>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <div clasName="d-flex" style={{ minWidth: '100px' }}>
                <Button
                  variant="light"
                  className="play-menu"
                  onClick={() => {
                    if (i18n.language === 'en') {
                      i18n.init();
                    } else {
                      i18n.changeLanguage('en');
                    }
                  }}
                >
                  <BsTranslate
                    size={CONSTANT.iconSize22px}
                    className="icon-color"
                  />
                </Button>
                <Button
                  variant="light"
                  className="play-menu"
                  onClick={() => setSettingModalShow(true)}
                >
                  <AiFillSetting
                    size={CONSTANT.iconSize22px}
                    className="icon-color"
                  />
                </Button>
                <Button
                  variant="light"
                  className="play-menu"
                  onClick={() => setHelpModalShow(true)}
                >
                  <BiHelpCircle
                    size={CONSTANT.iconSize22px}
                    className="icon-color"
                  />
                </Button>
              </div>
            </Col>

            {/* {t('画像選択モードここまで')} */}
          </>
        ) : (
          <>
            {/* {t('鑑賞モード')} */}
            <Col
              style={{ margin: 'auto 0' }}
              className="d-flex justify-content-between"
            >
              <div
                style={{
                  minWidth: '700px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h4 style={{ margin: 'auto 0' }}>{t('移動')}</h4>

                <Button
                  style={{ margin: 'auto 0', marginLeft: '30px' }}
                  onClick={goAllSky}
                  className="btn-style box_blue"
                >
                  {t('全天に戻る')}
                </Button>
                <DropdownButton
                  bsPrefix="btn-style box_blue dropdown_style"
                  as={ButtonGroup}
                  title={t('銀河系内天体')}
                >
                  {BEAUTIFUL_OBJECTS.objectsInOurGalaxy.map((item, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => {
                        goThisObject(item);
                      }}
                    >
                      {t(item.name)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton
                  bsPrefix="btn-style box_blue dropdown_style"
                  as={ButtonGroup}
                  title={t('銀河')}
                >
                  {BEAUTIFUL_OBJECTS.galaxies.map((item, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => {
                        goThisObject(item);
                      }}
                    >
                      {t(item.name)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton
                  bsPrefix="btn-style box_blue dropdown_style"
                  as={ButtonGroup}
                  title={t('重力レンズ天体')}
                >
                  {BEAUTIFUL_OBJECTS.gravityLens.map((item, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => {
                        goThisObject(item);
                      }}
                    >
                      {t(item.name)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton
                  bsPrefix="btn-style box_blue dropdown_style"
                  as={ButtonGroup}
                  title={t('銀河団')}
                >
                  {BEAUTIFUL_OBJECTS.galaxyCluster.map((item, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => {
                        goThisObject(item);
                      }}
                    >
                      {t(item.name)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div
                clasName="d-flex justify-content-end"
                style={{ minWidth: '100px' }}
              >
                <Button
                  variant="light"
                  className="play-menu"
                  onClick={() => setSettingModalShow(true)}
                >
                  <AiFillSetting
                    size={CONSTANT.iconSize22px}
                    className="icon-color"
                  />
                </Button>
                <Button
                  variant="light"
                  className="play-menu"
                  onClick={() => setHelpModalShow(true)}
                >
                  <BiHelpCircle
                    size={CONSTANT.iconSize22px}
                    className="icon-color"
                  />
                </Button>
              </div>
            </Col>
            {/* 鑑賞モードここまで */}
          </>
        )}
      </Row>
      <Row>
        {/* ビューワー関係はここから */}
        <StellarGlobe ref={globeRef}>
          {/* M31などの綺麗な画像 */}
          <PrettyPictures />

          {/* HSCの画像 */}
          <SspData
            baseUrl="//hscmap.mtk.nao.ac.jp/hscMap4/data/pdr3_wide"
            outline={false}
          />
          <SspData
            baseUrl="//hscmap.mtk.nao.ac.jp/hscMap4/data/pdr3_dud"
            outline={false}
          />

          {/* 背景の天の川 */}
          <EsoMilkyWay />

          {/* 星 */}
          <HipparcosCatalog />

          {/* 星座 */}
          <Constellation
            lang={i18n.language === 'ja' ? 'Hiragana' : 'English'}
            showNames
          />

          {/* 黄道 */}
          <Ecliptic />

          {/* 文字 */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <CelestialText {...textProp} />

          {selectImageMode ? (
            <>
              {fovy < 0.3 && (
                <>
                  {textTrackNumberPropArray.map((textTrackNumberProp) => (
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    <CelestialText {...textTrackNumberProp} />
                  ))}
                </>
              )}
              {/* HSCの画像データの枠 */}
              <SspOutline
                url="//hscmap.mtk.nao.ac.jp/hscMap4/data/pdr3_wide/area.json"
                color={[0, 1, 1, 1]}
              />
              <SspOutline
                url="//hscmap.mtk.nao.ac.jp/hscMap4/data/pdr3_dud/area.json"
                color={[1, 0.5, 1, 1]}
              />

              {/* Tract, Patch'枠関係 */}
              {/* Tract'選択肢 */}
              <TractSelector tracts={tracts} onClick={tractOnClick} />
              {selectedTractId !== undefined && (
                <>
                  {/* Tractがどれか選択されていたら選択中のTractを強調表示 */}
                  <Tract
                    tractId={selectedTractId}
                    style={selectedStyle}
                    baseLineWidth={15}
                  />
                  {/* Patch選択肢 */}
                  <PatchSelector
                    tractId={selectedTractId}
                    defaultStyle={defaultStyle}
                    patchStyle={progressColoredPatch}
                    validPatchIds={validPatchIds}
                    onClick={patchOnClick}
                  />
                  {selectedPatchId !== undefined && (
                    <Patch
                      tractId={selectedTractId}
                      patchId={selectedPatchId}
                      style={selectedStyle}
                      onClick={patchOnClick}
                    />
                  )}
                </>
              )}

              {/* 凡例 */}
              <ColorLegend
                TRACT_PATCH_COLORS={TRACT_PATCH_COLORS}
                showAutoProgress={showAutoProgress}
              />
            </>
          ) : (
            <>
              {/* 赤道 */}
              <Equatorial />
            </>
          )}
        </StellarGlobe>
        {/* ビューワー関係はここまで */}
      </Row>

      <SelectDateModal
        show={selectDateModalShow}
        onExit={() => {
          setSelectDateModalShow(false);
        }}
        onClickOkButton={dateOnClick}
        observedDates={observedDates}
        setObservedDates={setObservedDates}
        selectedDateIds={selectedDateIds}
        setSelectedDateIds={setSelectedDateIds}
        selectMultipleDates={selectMultipleDates}
      />

      <SelectImageModal
        show={selectImageModalShow}
        onExit={() => {
          setSelectImageModalShow(false);
        }}
        onClickOkButton={imageOnClick}
        images={images}
        setImages={setImages}
      />

      <AutoSelectResultModal
        show={showAutoSelectResult}
        onExit={() => {
          setShowAutoSelectResult(false);
        }}
        autoSelectResult={autoSelectResult}
      />

      <StellarGlobeSettingModal
        show={settingModalShow}
        onHide={() => {
          setSettingModalShow(false);
        }}
        selectMultipleDates={selectMultipleDates}
        setSelectMultipleDates={setSelectMultipleDates}
        selectImageMode={selectImageMode}
        setSelectImageMode={setSelectImageMode}
        showAutoProgress={showAutoProgress}
        setShowAutoProgress={setShowAutoProgress}
        title="設定"
      />

      <StellarGlobeHelpModal
        show={helpModalShow}
        onHide={() => {
          setHelpModalShow(false);
        }}
        title="ヘルプ"
      />

      <ErrorModal
        show={showErrorModal}
        setShow={setShowErrorModal}
        errorPlace={errorPlace}
        errorReason={errorReason}
        setLoading={setLoading}
      />

      <AlertModal
        alertModalShow={alertModalShow}
        onClickOk={() => {
          setAlertModalShow(false);
        }}
        alertMessage={alertMessage}
        alertButtonMessage={alertButtonMessage}
      />

      <AgreeModal show={agreeModalShow} setShow={setAgreeModalShow} />

      <MaintenanceModal
        show={maintenanceModalShow}
        setShow={setMaintenanceModalShow}
      />

      <LoadingButton loading={loading} processName="画像データコピー中" />
    </div>
  );
}

export default DataSelector;

DataSelector.propTypes = {
  setFileNames: PropTypes.func.isRequired,
  setFileObservedTimes: PropTypes.func.isRequired,
  statusIntervalRef: PropTypes.objectOf(PropTypes.number).isRequired,
  showAutoProgress: PropTypes.bool.isRequired,
  setShowAutoProgress: PropTypes.func.isRequired,
};
